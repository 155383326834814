<template>
  <b-overlay :show="checkingPermission || isLoading">
    <div v-if="checkingPermission || hasPermission">
      <div class="col-md-12 d-flex justify-content-end align-items-baseline">
        <b-button
          v-if="permissions.canCreateLeads && tabIndex==0"
          variant="primary"
          class="mb-2 mr-3"
          @click="$bvModal.show('import-file-modal')"
        >
          {{ $t('Import Excel / CSV') }}
        </b-button>

        <input
          ref="importExcel"
          type="file"
          class="d-none"
          @change="excelUploaded"
        >

        <input
          v-model="searchKeyWord"
          class="form-control w-25 w-md-auto mb-2"
          placeholder="Search Leads..."
          @input="searchLeads()"
        >

        <manage-permissions
          v-if="canManagePermissions"
          :permissions="permissionSlugs"
          class="ml-1"
        />
      </div>
      <b-tabs
        v-model="tabIndex"
        nav-class="nav-left"
        nav-wrapper-class="col-md-2"
        pills
        vertical
      >
        <!-- Leads -->
        <b-tab active>
          <template #title>
            <span class="font-weight-bold">
              <feather-icon icon="TargetIcon" />
              {{ $t('Leads') }}
            </span>
          </template>
          <leads
            :leads="leads.NEWLEAD"
            :permissions="permissions"
            @show-modal="showEmailComposer"
            @show-edit-modal="showEditModal"
            @show-status-update-modal="showStatusModal"
          />
        </b-tab>

        <!-- Sales -->
        <b-tab>
          <template #title>
            <span class="font-weight-bold">
              <feather-icon icon="CalendarIcon" />
              {{ $t('Followups') }}
            </span>
          </template>
          <leads
            :title="$t('Followups')"
            :leads="leads.INPROGRESS"
            :permissions="permissions"
            @show-modal="showEmailComposer"
            @show-edit-modal="showEditModal"
            @show-status-update-modal="showStatusModal"
          />
        </b-tab>

        <!-- Retention -->
        <b-tab>
          <template #title>
            <span class="font-weight-bold">
              <feather-icon icon="ShieldIcon" />
              {{ $t('Retention') }}
            </span>
          </template>
          <leads
            :title="$t('Retention')"
            :leads="leads.SIGNED"
            :permissions="permissions"
            @show-modal="showEmailComposer"
            @show-edit-modal="showEditModal"
            @show-status-update-modal="showStatusModal"
          />
        </b-tab>

        <!-- Closed -->
        <b-tab>
          <template #title>
            <span class="font-weight-bold">
              <feather-icon icon="XCircleIcon" />
              {{ $t('Closed') }}
            </span>
          </template>
          <leads
            :title="$t('Closed')"
            :leads="leads.DECLINED"
            :permissions="permissions"
            @show-modal="showEmailComposer"
            @show-edit-modal="showEditModal"
            @show-status-update-modal="showStatusModal"
          />
        </b-tab>
        <b-pagination
          v-if="leads[tabs[tabIndex][0]] && perPage < leads[tabs[tabIndex][0]].total"
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="leads[tabs[tabIndex][0]].total"
          align="right"
          aria-controls="leads-table"
          @input="getCompanyLeads"
        />
      </b-tabs>

      <status-update-modal
        ref="StatusUpdateModal"
        :lead="activeLead"
        :can-close-leads="permissions.canCloseLeads"
        @update-status="setStatus"
      />

      <leads-form
        :lead="selectedLead"
        :options="leadOptions"
        @refetch-leads="getCompanyLeads"
        @clear-selected="() => { selectedLead = null }"
      />

      <email-composer-modal :data="selectedEmail" />
      <import-file
        ref="importFile"
        @file-uploaded="excelUploaded"
      />
    </div>

    <no-permission v-else />
  </b-overlay>
</template>

<script>
import {
  BTabs, BTab, BPagination, BButton, BOverlay,
} from 'bootstrap-vue'
import useApollo from '@/plugins/graphql/useApollo'
import { checkPermission } from '@/utils/permissions'
import { leadStatus } from '@/const/common'
import _ from 'lodash'
import EventBus from '@/event-bus'
import { mapActions, mapGetters } from 'vuex'
import Leads from './leads/Index.vue'
import EmailComposerModal from './EmailComposer.vue'
import StatusUpdateModal from './StatusUpdateModal.vue'
import leadsForm from './leads/components/leadsForm.vue'
import ImportFile from './leads/components/ImportFile.vue'
import ManagePermissions from '../common/components/ManagePermissions.vue'
import NoPermission from '../common/components/NoPermission.vue'

export default {
  components: {
    BTabs,
    BTab,
    BPagination,
    BButton,
    BOverlay,
    Leads,
    EmailComposerModal,
    StatusUpdateModal,
    leadsForm,
    ImportFile,
    NoPermission,
    ManagePermissions,
  },
  data() {
    return {
      tabIndex: 0,
      selectedEmail: {},
      leads: [],
      activeLead: {},
      selectedLead: null,
      isLoading: false,
      leadOptions: leadStatus,
      perPage: 10,
      currentPage: 1,
      total: 0,
      searchKeyWord: '',
      tabs: [
        ['NEWLEAD'],
        ['INPROGRESS', 'WAITINGCLOSURE'],
        ['SIGNED'],
        ['DECLINED', 'NOANSWER'],
      ],
      permissionSlugs: ['leads.create', 'leads.follow', 'leads.close'],
      canManagePermissions: this.$store.state.permissions.canManagePermissions.value,
      checkingPermission: false,
      needPermissionCheck: false,
    }
  },
  computed: {
    ...mapGetters('permissions', [
      'canCreateLeads',
      'canFollowLeads',
      'canCloseLeads',
    ]),
    permissions() {
      return {
        canCreateLeads: this.canCreateLeads,
        canFollowLeads: this.canFollowLeads,
        canCloseLeads: this.canCloseLeads,
      }
    },
    hasPermission() {
      return this.permissions.canCreateLeads || this.permissions.canFollowLeads || this.permissions.canCloseLeads
    },
  },
  watch: {
    tabIndex() {
      this.getCompanyLeads(1)
      this.searchKeyWord = ''
      this.currentPage = 1
    },
  },
  mounted() {
    EventBus.$on('permission-updated', permission => {
      if (this.permissionSlugs.indexOf(permission) !== -1) {
        this.needPermissionCheck = true
        setTimeout(() => this.checkUserPermission(), 2000)
      }
    })
    this.checkUserPermission()

    this.getCompanyLeads()
  },
  methods: {
    ...mapActions('permissions', ['setPermissions']),
    checkUserPermission() {
      if (!this.hasPermission || this.needPermissionCheck) {
        this.checkingPermission = true
        checkPermission(this.permissionSlugs, permissions => {
          const canCreateLeads = permissions['leads.create'] === true
          const canFollowLeads = permissions['leads.follow'] === true
          const canCloseLeads = permissions['leads.close'] === true

          const payload = [
            { key: 'setCreateLeadsPermission', value: canCreateLeads },
            { key: 'setFollowLeadsPermission', value: canFollowLeads },
            { key: 'setCloseLeadsPermission', value: canCloseLeads },
          ]

          this.setPermissions(payload)

          this.$nextTick(() => {
            this.checkingPermission = false
            this.needPermissionCheck = false
          })
        })
      }
    },
    showEmailComposer(email) {
      this.selectedEmail = email
      this.$nextTick(() => { this.$bvModal.show('composeEmailModal') })
    },
    showStatusModal(lead) {
      this.$bvModal.show('status-update-modal')
      this.activeLead = lead
    },
    showEditModal(lead) {
      this.selectedLead = lead
      this.$nextTick(() => {
        this.$bvModal.show('addEditLeads')
      })
    },
    getCompanyLeads(currentPage = 1) {
      if (!this.leads[this.tabs[this.tabIndex][0]] || this.leads[this.tabs[this.tabIndex][0]].needRefetch || currentPage > 1) {
        this.isLoading = true

        if (currentPage) this.currentPage = currentPage
        useApollo.company.getCompanyLeads({
          first: this.perPage,
          page: this.currentPage,
          status: this.tabs[this.tabIndex],
          keyword: '',
        }).then(response => {
          const { data } = response.data.companyLeads
          this.leads[this.tabs[this.tabIndex][0]] = data
          this.leads[this.tabs[this.tabIndex][0]].total = response.data.companyLeads.total

          if (this.currentPage > 1) this.leads[this.tabs[this.tabIndex][0]].needRefetch = true
          else this.leads[this.tabs[this.tabIndex][0]].needRefetch = false
        }).finally(() => { this.isLoading = false })
      }
    },
    searchLeads: _.debounce(function () {
      if (this.searchKeyWord) {
        this.currentPage = 1
        this.isLoading = true
        useApollo.company.getCompanyLeads({
          first: this.perPage,
          page: 1,
          status: this.tabs[this.tabIndex],
          keyword: this.searchKeyWord,
        }).then(response => {
          const { data } = response.data.companyLeads
          this.leads[this.tabs[this.tabIndex][0]] = data
          this.leads[this.tabs[this.tabIndex][0]].total = response.data.companyLeads.total
          this.leads[this.tabs[this.tabIndex][0]].needRefetch = true
        }).finally(() => { this.isLoading = false })
      }
    }, 1000),
    setStatus({
      uuid, status, description, priority,
    }) {
      this.isProcessing = true
      useApollo.company.updateCompanyLeadStatus({
        projectUid: this.$store.state.project.selectedProject,
        leadUid: uuid,
        status,
        description,
        priority,
      }).then(response => {
        this.$refs.StatusUpdateModal.resetForm()
        this.showSuccessMessage({
          data: {
            message: response.data.updateCompanyLeadStatus.message,
          },
        })
        this.getCompanyLeads()
      }).catch(err => {
        this.showErrorMessage(err)
      }).finally(() => { this.isProcessing = false })
    },
    uploadExcel() {
      this.$refs.importExcel.click()
    },
    excelUploaded(file) {
      useApollo.company.batchImportLead({ projectUid: this.$store.state.project.selectedProject, file }).then(() => {
        this.getCompanyLeads(1)
        this.$refs.importFile.removeFiles()
        this.$bvModal.hide('import-file-modal')
        this.showSuccess('File Imported Sucessfully')
      })
    },
  },
}
</script>

<style scoped>
.no-events {
  pointer-events: none;
}
</style>
