<template>
  <b-modal
    id="import-file-modal"
    size="xl"
    hide-footer
    no-close-on-backdrop
    :title="$t('Import File')"
  >
    <b-row>
      <b-col md="12">
        <h5>{{ $t('actions.upload-or-drop-file') }}: </h5>
        <img
          src="@/assets/images/screenshot/upload-file-sample.png"
          class="img-fluid mt-2"
        >
      </b-col>
      <b-col
        class="py-3"
        md="12"
      >
        <vue-dropzone
          id="dropzone"
          ref="myVueDropzone"
          :options="dropzoneOptions"
          @vdropzone-file-added="fileAdded"
        />
        <span class="text-danger mt-1">
          <strong>
            {{ fileError }}
          </strong>
        </span>
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import { BModal, BRow, BCol } from 'bootstrap-vue'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    vueDropzone: vue2Dropzone,
  },

  data() {
    return {
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 150,
        maxFilesize: 10,
        uploadMultiple: false,
        dictDefaultMessage: `<i class='fa fa-cloud-upload'></i> ${this.$t('actions.drag-or-upload')}`,
      },
      fileError: '',
    }
  },

  methods: {
    fileAdded(file) {
      this.fileError = ''
      if (file.type !== 'text/csv') {
        this.fileError = this.$t('messages.upload-csv-excel')
        this.removeFiles()
        return
      }
      this.$emit('file-uploaded', file)
    },
    removeFiles() {
      this.$refs.myVueDropzone.removeAllFiles()
    },
  },

}
</script>
