<template>
  <b-card>
    <validation-observer ref="simpleRules">
      <b-overlay :show="!!isProcessing">
        <b-form>
          <b-form-group
            v-if="!finalizing"
            label="Company Name"
          >
            <validation-provider
              #default="{ errors }"
              name="Company Name"
              rules="required"
            >
              <b-form-input
                v-model="form.name"
                :state="errors.length > 0 ? false : null"
                placeholder="Company Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            v-if="!finalizing"
            label="Company Address"
          >
            <validation-provider
              #default="{ errors }"
              name="Company Address"
              rules="required"
            >
              <b-form-input
                v-model="form.address"
                :state="errors.length > 0 ? false : null"
                placeholder="Company Address"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            v-if="!finalizing"
            label="Company Email"
          >
            <validation-provider
              #default="{ errors }"
              name="Company Email"
              rules="required|email"
            >
              <b-form-input
                v-model="form.email"
                :state="errors.length > 0 ? false : null"
                placeholder="Company Email"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            v-if="!hiddenColumn.includes('ownerEmail')"
            label="Owner Email"
          >
            <validation-provider
              #default="{ errors }"
              name="Owner Email"
              rules="required|email"
            >
              <b-form-input
                v-model="form.ownerEmail"
                :state="errors.length > 0 ? false : null"
                placeholder="Owner Email"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Tracking Type">
            <validation-provider
              #default="{ errors }"
              name="Tracking Type"
              rules="required"
            >
              <v-select
                v-model="form.trackingType"
                :reduce="item => item.value"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="trackingTypeList"
                label="title"
                placeholder="Select Tracking Type"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Start Week On">
            <validation-provider
              #default="{ errors }"
              name="start_week"
              rules="required"
            >
              <v-select
                v-model="form.weekStartDay"
                :reduce="item => item.value"
                :options="dayOfWeek"
                label="title"
                placeholder="Select Starting Day"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Timezone offset">
            <validation-provider
              #default="{ errors }"
              name="Timezone offset"
              rules="required"
            >
              <v-select
                v-model="form.timezoneOffset"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="timeZones"
                :reduce="zone => zone.name"
                label="title"
                placeholder="Select a Timezone"
                :disabled="mode != 'create' ? true : false"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </b-overlay>
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-end"
        >
          <b-button
            variant="primary"
            :disabled="!!isProcessing"
            @click="handleOk"
          >
            <b-spinner
              v-show="!!isProcessing"
              small
            />
            {{ mode === 'create' ? 'Create' : 'Update' }}
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BRow, BCol, BOverlay, BFormGroup, BFormInput, BForm, BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import useApollo from '@/plugins/graphql/useApollo'
import { dayOfWeek } from './timeValues'

const defaultForm = () => ({
  uuid: null,
  name: '',
  address: '',
  email: '',
  trackingType: 0,
  timezoneOffset: 0,
  weekStartDay: 1,
  ownerEmail: '',
})

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BOverlay,
    BFormGroup,
    BFormInput,
    BForm,
    BSpinner,
    ValidationObserver,
    ValidationProvider,
    vSelect,
  },
  props: {
    mode: {
      type: String,
      default: () => 'create',
    },
    companyUid: {
      type: String,
      default: () => '',
    },
    // timeZones: {
    //   type: Array,
    //   default: () => [],
    // },
    finalizing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      email,
      form: {
        ...defaultForm,
      },
      hiddenColumn: [],
      isProcessing: false,
      trackingTypeList: [
        { title: 'INTERACTIVE', value: 'INTERACTIVE' },
        { title: 'ALL', value: 'ALL' },
      ],
      dayOfWeek,
      timeZones: [],
    }
  },
  computed: {
    selectedUid() {
      return this.companyUid || this.$store.state.project.selectedCompany
    },
  },
  watch: {
    mode: {
      handler(val) {
        if (val !== 'create') this.getCompanyInfo()
      },
      immediate: true,
    },
    companyUid: {
      handler(val) {
        if (val) this.getCompanyInfo()
      },
      immediate: true,
    },
  },
  mounted() {
    useApollo.company.getTimeZone().then(response => {
      this.timeZones = response.data.timezones.data.map(item => ({ name: item.name, title: item.displayName }))
    }).catch(error => {
      this.showErrorMessage(error)
    })
  },
  methods: {
    getCompanyInfo() {
      this.isProcessing = true
      useApollo.company.getMyCompanyInfo(this.selectedUid).then(response => {
        const company = response.data?.company
        this.$store.dispatch('project/STORE_COMPANY', company)
        this.hiddenColumn.push('ownerEmail')
        this.form = {
          ...company,
          timezoneOffset: company.timezoneOffset?.name,
        }
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => { this.isProcessing = false })
    },
    handleOk() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isProcessing = true
          const form = {
            companyUid: this.form.uuid,
            address: this.form.address,
            name: this.form.name,
            trackingType: this.form.trackingType,
            weekStartDay: this.form.weekStartDay,
            timezoneOffset: this.form.timezoneOffset,
            email: this.form.email,
            ownerEmail: this.form.ownerEmail,
          }
          if (this.finalizing) {
            this.isProcessing = true
            return this.$emit('create-company', form)
          }
          const method = this.mode === 'edit' ? 'updateCompany' : 'createCompany'
          useApollo.company[method](form).then(() => {
            if (this.mode) {
              this.$emit('onSave')
              this.showSuccess(this.mode === 'edit' ? 'Company Updated' : 'Company Created')
              return
            }
            this.getCompanyInfo()
          }).catch(error => {
            this.showErrorMessage(error)
          }).finally(() => { this.isProcessing = false })
        }
      })
    },
  },
}
</script>
