<template>
  <div>
    <b-card>
      <div class="d-flex justify-content-between align-items-baseline mb-2">
        <h4>{{ $t(title) }}</h4>
        <b-button
          v-if="permissions.canCreateLeads && title === 'Leads'"
          v-b-modal.addEditLeads
          variant="primary"
        >
          <feather-icon icon="PlusIcon" />
          {{ $t('Add Leads') }}
        </b-button>
      </div>

      <b-table
        id="leads-table"
        :items="leads"
        :fields="fields"
        selectable
        responsive
        hover
        show-empty
        @row-clicked="row => showDetails(row)"
      >
        <template #head()="data">
          <span class="text-capitalize">{{ $t(data.label) }}</span>
        </template>
        <template #cell(id)="data">
          {{ data.index + 1 }}

          <i :class="`fa fa-chevron-${data.item._showDetails ? 'up' : 'down'}`" />
        </template>
        <template #cell(status)="data">
          {{ getStatus(data.item.status) }}
        </template>
        <template #cell(priority)="data">
          <span class="text-capitalize">{{ data.item.priority }}</span>
        </template>
        <template #cell(email)="data">
          <b-link
            v-b-tooltip.hover
            :title="$t('Compose Email')"
            @click="emitEmail({uuid:data.item.uuid,email:data.item.email})"
          >
            {{ data.item.email }}
          </b-link>
        </template>

        <template #cell(actions)="data">
          <b-button
            v-b-tooltip.hover
            variant="outline-primary"
            size="sm"
            :title="$t('Edit Lead')"
            class="cursor-pointer"
            @click="showEditModal(data.item)"
          >
            <feather-icon icon="Edit2Icon" />
          </b-button>
          <b-button
            v-b-tooltip.hover
            variant="outline-primary"
            size="sm"
            :title="$t('Change Status of Lead')"
            class="ml-1 cursor-pointer"
            @click="$emit('show-status-update-modal',data.item)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
        </template>

        <!-- Details -->
        <template #row-details="data">
          <div class="ml-2">
            <h5>
              {{ $t('Contact Persons') }}:
            </h5>
            <b-row>
              <b-col
                v-for="contact, index in data.item.contacts"
                :key="index"
                md="4"
              >
                <b-card
                  border-variant="dark"
                >
                  <div class="d-flex">
                    <b-avatar
                      class="mr-1"
                      size="lg"
                    >
                      <feather-icon
                        icon="UserIcon"
                        size="20"
                      />
                    </b-avatar>
                    <div>
                      <h6>{{ contact.name }}</h6>
                      <p class="text-muted">
                        <b-link
                          v-b-tooltip.hover
                          :title="$t('Compose Email')"
                          class="py-1"
                          @click="emitEmail({uuid:data.item.uuid,email:contact.email,contactId:contact.id})"
                        >
                          <feather-icon icon="MailIcon" />
                          {{ contact.email }}
                        </b-link>
                      </p>

                    </div>
                  </div>
                  <div class="d-flex justify-content-between">
                    <p class="text-muted mb-0">
                      <feather-icon icon="MapPinIcon" />
                      {{ contact.address }}
                    </p>
                    <p class="text-muted mb-0">
                      <feather-icon icon="PhoneIcon" />
                      {{ contact.phone }}
                    </p>
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </div>
        </template>

        <template #empty>
          <p class="text-center">
            {{ $t('table.no-records') }}
          </p>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BButton, BCard, BTable, BAvatar, BCol, BRow, BLink, VBTooltip,
} from 'bootstrap-vue'
import { leadStatus } from '@/const/common'

export default {
  components: {
    BButton,
    BCard,
    BTable,
    BAvatar,
    BCol,
    BRow,
    BLink,

  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    title: {
      type: String,
      default: () => 'Leads',
    },
    leads: {
      type: Array,
      default: () => [],
    },
    permissions: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      fields: [
        { key: 'id', label: 'S.No.' },
        { key: 'name', label: 'Name' },
        { key: 'email', label: 'Email' },
        { key: 'phone', label: 'Phone' },
        { key: 'address', label: 'Address' },
        { key: 'status', label: 'Status' },
        { key: 'priority', label: 'Priority' },
        {
          key: 'actions',
          label: 'Actions',
          width: 200,
        },
      ],
      total: 0,
      perPage: 10,
      currentPage: 1,
      isProcessing: false,
      selectedLead: null,
      leadOptions: leadStatus,
      searchKeyWord: '',
    }
  },
  methods: {
    showDetails(row) {
      if (row._showDetails) {
        this.$set(row, '_showDetails', false)
      } else {
        this.leads.forEach(item => {
          this.$set(item, '_showDetails', false)
        })

        this.$nextTick(() => {
          this.$set(row, '_showDetails', true)
        })
      }
    },
    showEditModal(lead) {
      this.$emit('show-edit-modal', lead)
    },
    getStatus(status) {
      return this.leadOptions.find(option => option.value === status).text
    },
    emitEmail(data) {
      this.$emit('show-modal', data)
    },
  },
}
</script>

<style lang="scss">
#leads-table {
  min-height: 100px;
}
td{
  padding: 7px !important;
}
</style>
