var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"addEditLeads","title":_vm.$t('Add Contact Info'),"ok-title":_vm.form.uuid ? _vm.$t('Update') : _vm.$t('Submit'),"cancel-title":_vm.$t('Cancel'),"size":"lg","no-close-on-backdrop":""},on:{"ok":_vm.handleSubmit,"shown":_vm.setForm,"hidden":_vm.clearForm}},[_c('validation-observer',{ref:"leadsForm"},[_c('b-row',[_c('b-col',[_c('h4',{staticClass:"font-weight-bold mb-1"},[_vm._v(" "+_vm._s(_vm.$t('Company Info'))+": ")]),_c('validation-provider',{attrs:{"name":"company name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Company Name')}},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Company Name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"company address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Company Address')}},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Company Address"},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"company phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Company Phone')}},[_c('b-form-input',{attrs:{"type":"tel","placeholder":"Company Phone"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"company email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Company Email')}},[_c('b-form-input',{attrs:{"type":"email","placeholder":"Company Email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',[_c('h4',{staticClass:"font-weight-bold mb-1"},[_vm._v(" "+_vm._s(_vm.$t('Contact Persons'))+": ")]),_vm._l((_vm.contacts),function(contact,index){return _c('div',{key:index},[_c('p',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(("contact-" + index)),expression:"`contact-${index}`"}]},[_vm._v(" "+_vm._s(_vm.$t('Contact'))+" "+_vm._s(index + 1)+" "),_c('feather-icon',{attrs:{"icon":_vm.visibleAccordion === ("contact-" + index) ? 'ChevronUpIcon' : 'ChevronDownIcon'}})],1),_c('b-collapse',{attrs:{"id":("contact-" + index),"visible":_vm.activeContact === ("contact-" + index) ? true : false}},[_c('validation-provider',{attrs:{"name":"name","rules":index == 0 ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Name')}},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Name"},model:{value:(contact.name),callback:function ($$v) {_vm.$set(contact, "name", $$v)},expression:"contact.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"address","rules":index == 0 ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Address')}},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Address"},model:{value:(contact.address),callback:function ($$v) {_vm.$set(contact, "address", $$v)},expression:"contact.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"phone","rules":index == 0 ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Phone')}},[_c('b-form-input',{attrs:{"type":"tel","placeholder":"Phone"},model:{value:(contact.phone),callback:function ($$v) {_vm.$set(contact, "phone", $$v)},expression:"contact.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"email","rules":index == 0 ? 'required|email' : 'email'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Email')}},[_c('b-form-input',{attrs:{"type":"email","placeholder":"Email"},model:{value:(contact.email),callback:function ($$v) {_vm.$set(contact, "email", $$v)},expression:"contact.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1)}),(_vm.canAddContacts)?_c('b-button',{staticClass:"mt-1",attrs:{"variant":"outline-primary"},on:{"click":_vm.addContact}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","color":"#7367f0"}}),_vm._v(" "+_vm._s(_vm.$t('Add Contact'))+" ")],1):_vm._e()],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }