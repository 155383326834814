var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"simpleRules"},[_c('b-overlay',{attrs:{"show":!!_vm.isProcessing}},[_c('b-form',[(!_vm.finalizing)?_c('b-form-group',{attrs:{"label":"Company Name"}},[_c('validation-provider',{attrs:{"name":"Company Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Company Name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,913130572)})],1):_vm._e(),(!_vm.finalizing)?_c('b-form-group',{attrs:{"label":"Company Address"}},[_c('validation-provider',{attrs:{"name":"Company Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Company Address"},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1155014412)})],1):_vm._e(),(!_vm.finalizing)?_c('b-form-group',{attrs:{"label":"Company Email"}},[_c('validation-provider',{attrs:{"name":"Company Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Company Email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2435727500)})],1):_vm._e(),(!_vm.hiddenColumn.includes('ownerEmail'))?_c('b-form-group',{attrs:{"label":"Owner Email"}},[_c('validation-provider',{attrs:{"name":"Owner Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Owner Email"},model:{value:(_vm.form.ownerEmail),callback:function ($$v) {_vm.$set(_vm.form, "ownerEmail", $$v)},expression:"form.ownerEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2539613803)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Tracking Type"}},[_c('validation-provider',{attrs:{"name":"Tracking Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"reduce":function (item) { return item.value; },"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.trackingTypeList,"label":"title","placeholder":"Select Tracking Type"},model:{value:(_vm.form.trackingType),callback:function ($$v) {_vm.$set(_vm.form, "trackingType", $$v)},expression:"form.trackingType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Start Week On"}},[_c('validation-provider',{attrs:{"name":"start_week","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"reduce":function (item) { return item.value; },"options":_vm.dayOfWeek,"label":"title","placeholder":"Select Starting Day"},model:{value:(_vm.form.weekStartDay),callback:function ($$v) {_vm.$set(_vm.form, "weekStartDay", $$v)},expression:"form.weekStartDay"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Timezone offset"}},[_c('validation-provider',{attrs:{"name":"Timezone offset","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.timeZones,"reduce":function (zone) { return zone.name; },"label":"title","placeholder":"Select a Timezone","disabled":_vm.mode != 'create' ? true : false},model:{value:(_vm.form.timezoneOffset),callback:function ($$v) {_vm.$set(_vm.form, "timezoneOffset", $$v)},expression:"form.timezoneOffset"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"md":"12"}},[_c('b-button',{attrs:{"variant":"primary","disabled":!!_vm.isProcessing},on:{"click":_vm.handleOk}},[_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(!!_vm.isProcessing),expression:"!!isProcessing"}],attrs:{"small":""}}),_vm._v(" "+_vm._s(_vm.mode === 'create' ? 'Create' : 'Update')+" ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }